.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px; /* Ensures a fixed navbar height */
  background-color: rgb(217, 217, 217);
  display: flex;
  align-items: baseline;
  line-height: 100px;
  min-width: 0px;
  z-index: 1000;
  flex-wrap: nowrap;
}

.navbar-left,
.navbar-center,
.navbar-right {
  display: flex;
  white-space: nowrap; /* Prevents text from breaking into new lines */
}

.navbar-left {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 32px;
  padding: 0 64px;
}

.navbar-center {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  padding: 0 64px;
}

.navbar-right {
  margin-left: auto; /* Pushes to the right */
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  padding: 0 32px;
}

.navbar-square-1 {
  width: 32px; /* Adjust size */
  height: 32px;
  background-color: rgb(120, 120, 120); /* Change to preferred color */
  position: absolute;
  top: 0px;
  left: 0px;
}

.navbar-square-2 {
  width: 32px; /* Adjust size */
  height: 32px;
  background-color: rgb(39, 234, 154); /* Change to preferred color */
  position: absolute;
  top: 68px;
  right: 0px;
}

/* Navigation Links */
.nav-link {
  color: #000000; /* Light gray */
  text-decoration: none;
  margin-right: 20px;
  border-radius: 0px;
  transition: background-color 0.3s, box-shadow 0.3s;
  text-align: center;
}

/* Hover Effect */
.nav-link:hover {
  color:  rgb(70, 70, 70);
  
}

/* Active Link Styling */
.active {
  color:  rgb(0, 0, 0);
  font-weight: 600;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-link {
    margin-right: 10px;
    font-size: 16px;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px 20px;
  }

  .navbar-center {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

}