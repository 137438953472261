@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow: hidden; */
}

.container {
  height: 100vh; /* Full viewport height */
  /* overflow: hidden; */
}

section {
  position: relative; /* Set section to relative */
  height: 100vh; /* Each section takes up the full viewport height */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  font-size: 5rem; /* Large font size for section content */
  color: white; /* Text color */
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}

.scrollButtons {
  position: fixed;
  right: 20px; /* Keep it on the right edge */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for true vertical centering */
  display: flex;
  flex-direction: column; /* Stack the circles vertically */
  align-items: center; /* Center align the circles */
  gap: 10px; /* Space between circles */
}

.circle {
  width: .7vh; /* Default size */
  height: .7vh; /* Default size */
  background-color: #333; /* Default color */
  border-radius: 50%; /* Makes the div circular */
  transition: width 0.3s, height 0.3s, background-color 0.3s; /* Smooth transition for size and color */
  cursor: pointer; /* Change cursor to pointer */
  opacity: .7;
}

.circle.active {
  width: 1.5vh; /* Larger size for active section */
  height: 1.5vh; /* Larger size for active section */
  background-color: #333; /* Highlight color */
  opacity: 1;
}

